import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogProps, Divider } from '@mui/material';

type ComponentProps = {
    handleClose: () => void;
    handleSuccess: () => void;
    successLabel: string | JSX.Element;
    cancelLabel?: string | JSX.Element;
    size?: DialogProps['maxWidth'];
    children: JSX.Element;
    title?: string;
    showCancelButton?: boolean;
};

export const GenericModal = ({
    handleClose,
    handleSuccess,
    successLabel,
    cancelLabel = 'Cancel',
    size = 'sm',
    children,
    title,
    showCancelButton = true,
}: ComponentProps) => {
    return (
        <Dialog open={true} maxWidth={size} fullWidth keepMounted>
            {title && <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>}
            <Divider></Divider>
            <DialogContent>
                <DialogContent sx={{ textAlign: 'center' }}>{children}</DialogContent>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                {showCancelButton && <Button onClick={handleClose}>{cancelLabel}</Button>}
                <Button variant="contained" color="info" onClick={handleSuccess}>
                    {successLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
