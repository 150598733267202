import {
    Alert,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { Errors, ISettings } from '../../../../types/shortlist';
import { useSelector } from 'react-redux';
import { selectProfileData } from '../../../../redux/selectors/profileSelector';
import { useQuery } from '@tanstack/react-query';
import { getInstance } from '../../../../services/fetch';
import { useState } from 'react';
import { CustomMenuItem, CustomListItemText } from '../../../../components/global';
import { selectIsUserAdmin } from '../../../../redux/selectors/userSelector';
import { settingPermission } from '../../../../utils/permission';
import { useNavigate } from 'react-router-dom';

type UserOptions = { id: string; label: string; value: string };

const Settings = ({
    values,
    setValue,
    save,
    errors,
    setOrganization,
}: {
    values?: any;
    setValue?: (data: any) => void;
    save: any;
    errors: Errors;
    setOrganization: any;
}) => {
    const profileData = useSelector(selectProfileData);
    // const userOrganization = useSelector(selectUserOrganization);
    const { settings: settingError } = errors;
    const settings: ISettings = values.settings;
    const [userOptions, setUserOptions] = useState<UserOptions[]>([] as UserOptions[]);
    const navigate = useNavigate();
    const isAdmin = useSelector(selectIsUserAdmin);

    const handleChange = (event: any) => {
        const {
            target: { name, value },
        } = event;
        setValue && setValue({ target: { name, value } });
    };

    const handleRadioChange = (event: any) => {
        const {
            target: { value },
        } = event;
        const intValue = parseInt(value);
        handleChange({ target: { name: 'status', value: intValue } });
    };

    const handleSelectChange = (event: any) => {
        const {
            target: { name, value },
        } = event;
        handleChange({ target: { name, value } });
    };
    const isOwner = settings?.owner === profileData?.userId;
    const Organization = useQuery(['userList', settings?.organization], async () => {
        if (isAdmin) {
            const response = await getInstance().get<{ data: any; error: string }>(`/admin/org/${settings.organization}/users`);
            if (response.error) {
                throw new Error(response.error);
            }
            setUserOptions(
                (response.data.users || []).map((item: any) => {
                    const { userId, firstname, surname } = item;
                    return {
                        id: userId,
                        label: `${firstname} ${surname}`,
                        value: userId,
                    };
                })
            );
            setOrganization(response.data);
            return response.data;
        }
        const response = await getInstance().get<{ data: any; error: string }>(`/employer/organization/users`);
        if (response.error) {
            throw new Error(response.error);
        }
        setUserOptions(
            (response.data.users || []).map((item: any) => {
                const { userId, firstname, surname } = item;
                return {
                    id: userId,
                    label: `${firstname} ${surname}`,
                    value: userId,
                };
            })
        );
        setOrganization(response.data);
        return response.data;
    });

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                    <Alert icon={false}>
                        <Typography variant="body1" fontSize={18}>
                            These settings describe how this job will be handled within the platform. This information will NOT be visible to candidates.
                        </Typography>
                    </Alert>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <TextField
                        fullWidth
                        label="ID"
                        name="id"
                        value={settings?.id || ''}
                        onChange={handleChange}
                        disabled
                        error={!!settingError?.find((item) => item.name === 'id') || false}
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <TextField
                        fullWidth
                        label="Company"
                        name="organization"
                        value={Organization.data?.org?.name || ''}
                        onChange={handleChange}
                        disabled
                        error={!!settingError?.find((item) => item.name === 'organization') || false}
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <TextField
                        fullWidth
                        label="Job Author"
                        name="owner"
                        value={`${userOptions?.find((item) => item?.value === settings?.owner)?.label}` || ''}
                        onChange={handleChange}
                        disabled
                        error={!!settingError?.find((item) => item.name === 'owner') || false}
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <FormControl fullWidth>
                        <InputLabel id="job-owners-label">Job Owner(s)</InputLabel>
                        <Select
                            disabled={settingPermission({ isAdmin, isOwner })}
                            labelId="job-owners-label"
                            multiple
                            name="sharedTo"
                            value={settings?.sharedTo || []}
                            placeholder="List all the working arrangements that may be possible for this job"
                            onChange={handleSelectChange}
                            input={<OutlinedInput label="Job Owner(s)" />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <Typography variant="grayText">List all the working arrangements that may be possible for this job</Typography>;
                                }
                                return selected.map((selected: string) => userOptions.find((option) => option.value === selected)?.label).join(', ');
                            }}
                        >
                            {(userOptions || []).map((data) => (
                                <CustomMenuItem key={data.id} value={data.value}>
                                    <Checkbox checked={(settings?.sharedTo || []).indexOf(data.value) > -1} />
                                    <CustomListItemText primary={data.label} />
                                </CustomMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
                <Grid item xs={12} md={3} lg={3}>
                    <TextField
                        fullWidth
                        label="Created Date"
                        value={settings?.createdDate || ''}
                        name="createdDate"
                        onChange={handleChange}
                        disabled
                        error={!!settingError?.find((item) => item.name === 'createdDate') || false}
                    />
                </Grid>

                <Grid item xs={12} md={9} lg={9}>
                    <TextField
                        disabled={settingPermission({ isAdmin, isOwner })}
                        fullWidth
                        label="Other Reference Numbers"
                        placeholder="Use this field to record any internal and client numbers related to this role"
                        value={settings?.referenceNumber || ''}
                        name="referenceNumber"
                        onChange={handleChange}
                        error={!!settingError?.find((item) => item.name === 'referenceNumber') || false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: 'flex' }}>
                        <Typography
                            variant="body1"
                            style={{ minWidth: 120, marginTop: '10px', marginRight: '2rem' }}
                            color={`${settingError?.find((item) => item.name === 'status') ? 'red' : 'primary'}`}
                        >
                            Sharing Status*
                        </Typography>
                        <RadioGroup row={false} onChange={handleRadioChange} value={settings?.status || 1}>
                            <FormControlLabel
                                value={1}
                                control={<Radio />}
                                disabled={settingPermission({ isAdmin, isOwner })}
                                label="Share with assigned Jobowners only"
                            />
                            <FormControlLabel
                                value={2}
                                control={<Radio />}
                                disabled={settingPermission({ isAdmin, isOwner })}
                                label={
                                    <Grid display={'flex'} alignItems={'center'}>
                                        <p> Share with all {profileData.organisationName} users with employer access </p>
                                        <Tooltip title="Sharing will include assigned Job owners">
                                            <i className={`fa-solid fa-circle-info ml-1`}></i>
                                        </Tooltip>
                                    </Grid>
                                }
                            />
                            <FormControlLabel
                                value={3}
                                control={<Radio />}
                                disabled={settingPermission({ isAdmin, isOwner })}
                                label={
                                    <Grid display={'flex'} alignItems={'center'}>
                                        <p>Published on Public Job board</p>
                                        <Tooltip title={`Sharing will include all ${profileData.organisationName} users with employer access`}>
                                            <i className="fa-solid fa-circle-info ml-1"></i>
                                        </Tooltip>
                                    </Grid>
                                }
                            />
                            <FormControlLabel value={5} control={<Radio />} disabled={settingPermission({ isAdmin, isOwner })} label="Closed & Archived" />
                        </RadioGroup>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled={settingPermission({ isAdmin, isOwner })}
                        fullWidth
                        label="Description (optional)"
                        multiline
                        placeholder="Add any notes to describe the background and purpose of this job or list."
                        minRows={4}
                        name="additionalSettingNotes"
                        value={settings?.additionalSettingNotes || ''}
                        onChange={handleChange}
                        error={!!settingError?.find((item) => item.name === 'additionalSettingNotes') || false}
                    />
                </Grid>
            </Grid>
            {!settingPermission({ isAdmin, isOwner }) && (
                <Grid
                    sx={{ flexGrow: 1, pl: 3, pt: 2 }}
                    container
                    spacing={2}
                    padding={1}
                    alignContent="flex-end"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item sm={1}>
                        <Button variant="outlined" onClick={() => navigate(-1)}>
                            cancel
                        </Button>
                    </Grid>
                    <Grid item sm={1}>
                        <Button variant="contained" onClick={() => save && save('settings')}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default Settings;
