import React, { useState, useEffect, ChangeEvent, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { clone } from 'ramda';
import styled from 'styled-components';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    Select,
    MenuItem,
    DialogActions,
    Chip,
    TextField,
    Divider,
    Typography,
    Grid,
    Checkbox,
    ListItemText,
    Autocomplete,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Paper,
    CircularProgress,
    FormControlLabel,
    Switch,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { GridSelectionModel } from '@mui/x-data-grid';
import SearchResultsTable from '../../../components/SearchResultsTable';
import { MenuProps } from './../Profile/ProfileDetail';
import { LocationState } from '../../../types/location';
import { CriteriaType, SearchMemberModifier, SearchRequest, SearchRestriction } from '../../../types/search';
import { Skill } from '../../../types/skill';
import { selectSearchCriteria, selectSearchResults } from '../../../redux/selectors/searchSelector';
import { selectGeographyData } from '../../../redux/selectors/geographySelector';
import { selectSkillsData } from '../../../redux/selectors/skillsSelector';
import { selectProfileWizardCompleted } from '../../../redux/selectors/profileSelector';
import { getSkillsFramework } from '../../../redux/actions/skillsAction';
import { getGeographyFramework } from '../../../redux/actions/geographyAction';
import { clearSearch, setSearchCriteria } from '../../../redux/actions/searchAction';
import { mapValueToLabel } from '../../../utils/mapValueToLabel';
import {
    searchAttributeModifier,
    affirmativeActionFilter,
    securityClearanceDropdown,
    auCitizenshipStatusDropdown,
    seekingDropdown,
} from '../../../data/dropDownData';
import useAdminEmailMutation from '../../../hooks/useAdminEmailMutation';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../redux/actions/snackbarAction';

const CustomPaper = (props: any) => (
    <Paper
        {...props}
        sx={{
            minHeight: 'auto',
        }}
    />
);

interface AutocompleteType {
    id: string;
    origId?: string;
    title?: string;
    level?: number;
    keywords?: any;
    lastChainElement: string;
}

const getKeywordsString = (keywords?: string[]) => {
    return keywords?.length ? `(${keywords.join(',')})` : '';
};

const CustomListItemText = styled(ListItemText)`
    & .MuiListItemText-primary {
        font-size: 0.875rem !important;
    }
`;

const CustomMenuItem = styled(MenuItem)`
    white-space: normal;
    height: 3rem;
`;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const DEFAULT_EMAIL_CONTENT = [
    'Hello <firstname>,',
    '',
    '',
    '',
    'Kind Regards - Mark',
    '',
    'Mark Korsten',
    'CEO, AUKUS Jobs',
    'info@aukusjobs.com',
    '+61 411 317 218',
].join('\n');

const AdminUsers = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const wizardCompleted = useSelector(selectProfileWizardCompleted);
    const skills = useSelector(selectSkillsData);
    const geography = useSelector(selectGeographyData);
    const searchCriteria = useSelector(selectSearchCriteria);
    const searchResults = useSelector(selectSearchResults);
    const [selected, setSelected] = useState<[string, string, string, string[]]>(['', '', '', []]);
    const [selectedSkill, setSelectedSkill] = useState<Skill[]>([]);
    const [criteriaModalOpen, setCriteriaModalOpen] = useState(false);
    const [modifier, setModifier] = useState<SearchMemberModifier>('HIGHLY_DESIRABLE');
    // const [experience, setExperience] = useState<Experience>('');
    const [criteriaType, setCriteriaType] = useState<CriteriaType>('SKILLSET');
    const [tempSearchCriteria, setTempSearchCriteria] = useState<SearchRequest>({});
    const [candidateId, setCandidateId] = useState<string>('');
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [searchRestriction, setSearchRestriction] = useState<SearchRestriction>({
        candidateId: '',
        seeking: [],
        affirmativeAction: [],
        securityClearance: [],
        auCitizenshipStatus: [],
    });
    const [selectedSkills, setSelectedSkills] = useState<any>([]);
    const [searchString, setSearchString] = useState<string>('');
    const [selectedIds, setSelectedIds] = useState<GridSelectionModel>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [emailBody, setEmailBody] = useState(DEFAULT_EMAIL_CONTENT);
    const [subject, setSubject] = useState('');
    const inputRef = useRef<any>(null);

    const state = location.state as LocationState;

    useEffect(() => {
        dispatch(getGeographyFramework());
        dispatch(getSkillsFramework());
        return () => {
            dispatch(clearSearch());
        };
    }, [dispatch]);

    useEffect(() => {
        if (!Object.keys(searchCriteria).length) {
            dispatch(setSearchCriteria({ profile: {}, geography: {}, skills: {}, searchBy: 'admin' }));
        }
    }, [dispatch, searchCriteria]);

    useEffect(() => {
        if (state?.authProvider && !wizardCompleted) {
            navigate('/account');
        }
    }, [state, wizardCompleted, navigate]);

    const handleCandidateIdChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCandidateId(event.target.value);
    };

    const handleSearchClick = () => {
        handleRestrictionChange({ target: { name: 'candidateId', value: candidateId.trim() } });
    };

    const handleCriteriaChange = (_event: React.MouseEvent<HTMLElement> | null, newSearchCriteria: CriteriaType) => {
        if (!newSearchCriteria) {
            return;
        }

        setCriteriaType(newSearchCriteria);
        setSelectedSkill([]);
        setSelected(['', '', '', []]);
    };

    const handleClickCriteriaOpen = () => {
        setCriteriaModalOpen(true);
    };

    const handleCriteriaClose = () => {
        setSelectedSkill([]);
        setSelected(['', '', '', []]);
        // setExperience('');
        setModifier('HIGHLY_DESIRABLE');
        setCriteriaType('SKILLSET');
        setSearchCriteria({ profile: {}, geography: {}, skills: {}, searchBy: 'admin' });
        setCriteriaModalOpen(false);
        setSelectedSkills([]);
    };

    const handleRestrictionChange = (event: { target: { name: string; value: string | string[] } }) => {
        setSearchRestriction({ ...searchRestriction, [event.target.name]: event.target.value });

        const { candidateId, seeking, affirmativeAction, securityClearance, auCitizenshipStatus } = searchRestriction;

        const newCriteria: Record<string, boolean | string | string[]> = {
            candidateId,
            seeking,
            securityClearance,
            auCitizenshipStatus,
        };

        affirmativeAction.forEach((value: string) => (newCriteria[value] = true));

        if (event.target.name === 'candidateId') {
            newCriteria.candidateId = event.target.value;
        } else if (event.target.name === 'securityClearance') {
            newCriteria.securityClearance = event.target.value;
        } else if (event.target.name === 'auCitizenshipStatus') {
            newCriteria.auCitizenshipStatus = event.target.value;
        } else if (event.target.name === 'seeking') {
            newCriteria.seeking = event.target.value;
        } else {
            affirmativeAction.forEach((value: string) => delete newCriteria[value]);
            (event.target.value as string[]).forEach((value: string) => (newCriteria[value] = true));
        }

        const mergedAdd = {
            profile: {
                ...newCriteria,
            },
        };

        dispatch(setSearchCriteria({ ...searchCriteria, ...mergedAdd, searchBy: 'admin' }));
    };

    const handleAddSearch = () => {
        dispatch(
            setSearchCriteria({
                ...searchCriteria,
                ...tempSearchCriteria,
                searchBy: 'admin',
            })
        );
        setSearchRestriction({ ...searchRestriction });
        handleCriteriaClose();
    };

    const handleClearSearch = () => {
        setSearchRestriction({
            candidateId: '',
            seeking: [],
            affirmativeAction: [],
            securityClearance: [],
            auCitizenshipStatus: [],
        });
        dispatch(setSearchCriteria({ searchBy: 'admin' }));
    };

    const handleDeleteSearchChip = (keys: string[]) => {
        const clonedCriteria: any = clone(searchCriteria) || {};
        const target1 = clonedCriteria[keys[0] || ''] || {};

        delete target1[keys[1] || ''];

        dispatch(setSearchCriteria(clonedCriteria));

        if (keys[0] === 'profile') {
            if (keys[1] === 'candidateId') {
                setSearchRestriction({
                    ...searchRestriction,
                    candidateId: '',
                });

                setCandidateId('');

                return;
            }

            if (keys[1] === 'securityClearance') {
                setSearchRestriction({
                    ...searchRestriction,
                    securityClearance: [],
                });

                return;
            }

            if (keys[1] === 'auCitizenshipStatus') {
                setSearchRestriction({
                    ...searchRestriction,
                    auCitizenshipStatus: [],
                });

                return;
            }

            if (keys[1] === 'seeking') {
                setSearchRestriction({
                    ...searchRestriction,
                    seeking: [],
                });

                return;
            }

            const affirmativeAction = searchRestriction.affirmativeAction.filter((value) => value !== keys[1]);

            setSearchRestriction({
                ...searchRestriction,
                affirmativeAction,
            });
        }
    };

    const handleAutoCompleteChange = (selectedValues: any) => {
        let merged: any = {};
        setSearchRestriction({ ...searchRestriction });
        const selectedSkillsArray: any = [];
        selectedValues.forEach((selectedAutoCompleteItem: any) => {
            const skillItem = skills.find((skill: any) => skill.id === selectedAutoCompleteItem?.origId) || ({} as Skill);
            selectedSkillsArray.push(skillItem);
            let item;
            if (selectedAutoCompleteItem?.origId) {
                if (modifier === 'MANDATORY') {
                    item = {
                        ...searchCriteria['skills'],
                        [selectedAutoCompleteItem?.origId]: {
                            modifier: modifier,
                            key: 'experience',
                            // value: selectedSkillExp,
                            value: undefined,
                        },
                    };
                } else {
                    item = {
                        ...searchCriteria['skills'],
                        [selectedAutoCompleteItem?.origId]: {
                            modifier: modifier,
                        },
                    };
                }
            }
            // merged.push(item)
            merged = { ...merged, ...item };
            return item;
        });
        setSelectedSkill([...selectedSkillsArray]);
        const payload = {
            ...searchCriteria,
            skills: merged,
        };
        setTempSearchCriteria(payload);
    };

    const handleDesirabilityChange = (event: any) => {
        const { value } = event.target;

        setModifier(value);

        let merged: any;

        if (selectedSkill.length > 0) {
            /** Remove if block statement if you want to enable experience bracket. This logic is handled by handleExpChange */
            selectedSkill.forEach((item) => {
                if (value === 'MANDATORY') {
                    merged = {
                        ...merged,
                        ...searchCriteria['skills'],
                        [item?.id as string]: {
                            modifier: value,
                            key: 'experience',
                            value: 'ANY',
                        },
                    };
                } else {
                    merged = {
                        ...merged,
                        ...searchCriteria['skills'],
                        [item?.id as string]: {
                            modifier: value,
                            key: 'experience',
                        },
                    };
                }
            });
        }
        const payload = {
            ...tempSearchCriteria,
            skills: merged,
        };

        setTempSearchCriteria(payload);
        setSearchRestriction({ ...searchRestriction });
    };

    function getParents(item: Skill, source: Skill[]): any {
        if (item.parents && Array.isArray(item.parents) && item.parents.length > 0) {
            return item.parents
                .filter((p) => p !== item.id)
                .map((p) => {
                    const target = source.find((s) => s.id === p);
                    if (!target) {
                        return [[item.id]];
                    }
                    const parents = getParents(target, source);
                    return parents.map((newP: string[]) => [...newP, item.id]);
                })
                .flat();
        }
        return [[item.id]];
    }

    function getLevel(chain: any) {
        const level = chain.length;
        return level;
    }

    function getAutocomplete(source: Skill[]): AutocompleteType[] {
        const autoCompleteSkills = source
            .map((skill) => ({ ...skill, chain: getParents(skill, source) }))
            .map((skill: any) =>
                (skill.chain || []).map((chain: any, idx: number) => ({
                    chain,
                    id: `${skill.id}-${idx}`,
                    origId: skill.id,
                    keywords: skill.keywords,
                    display: skill.display,
                }))
            )
            .flat()
            .map((flatSkill) => ({
                ...flatSkill,
                id: flatSkill.origId,
                chain: undefined,
                title: flatSkill.display,
                lastChainElement: flatSkill.chain[flatSkill.chain.length - 1],
                level: getLevel(flatSkill.chain),
            }))
            .filter((s) => s.level === 4);

        // autoCompleteSkills.filter((item, index) => autoCompleteSkills.indexOf(item) === index);
        const uniqueAC = autoCompleteSkills.filter(
            (thing, index, self) =>
                index ===
                self.findIndex(
                    (t) =>
                        //unique on id
                        t.id === thing.id
                    // && t.title === thing.title
                )
        );

        return uniqueAC.sort(compareTitle);
    }

    function compareTitle(a: any, b: any) {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    }

    const handleDropdownChange = async (level: number, value: any) => {
        const newArr = [...selected];
        newArr[level] = value;
        for (let i = level + 1; i < 4; i++) {
            newArr[i] = '';
        }
        const selectedDropdownItems: any = [];
        if (typeof value === 'object' && value.length === 0) {
            setSelectedSkills([]);
        }
        if (newArr[3].length > 0 && level === 3) {
            await (newArr[3] as Array<string>).map((item: any) => selectedDropdownItems.push(skills.find((skill) => skill.id === item)));
            setSelectedSkills(value);
        }

        setSearchRestriction({ ...searchRestriction });
        setSelected(newArr as [string, string, string, string[]]);
        setSelectedSkill([...selectedDropdownItems]);
        let merged: any;

        selectedDropdownItems.map((item: any) => {
            if (item?.id) {
                if (modifier === 'MANDATORY') {
                    merged = {
                        ...merged,
                        ...searchCriteria['skills'],
                        [item?.id]: {
                            modifier: modifier,
                            key: 'experience',
                            value: undefined,
                        },
                    };
                } else {
                    merged = {
                        ...merged,
                        ...searchCriteria['skills'],
                        [item?.id]: {
                            modifier: modifier,
                        },
                    };
                }

                const payload = {
                    ...searchCriteria,
                    skills: merged,
                };

                setTempSearchCriteria(payload);
            }
            return item;
        });
    };

    const hasSkillsSearch = useMemo(() => {
        return Object.keys(searchCriteria?.skills || {}).length > 0;
    }, [searchCriteria?.skills]);

    const [isReady, setIsReady] = useState(false);

    // Set default filter values
    useEffect(() => {
        if (!isReady) {
            if (searchCriteria && Object.keys(searchCriteria).length) {
                setCandidateId(searchCriteria.profile?.candidateId || '');

                const affirmationActionObj = {
                    aboriginalOrTorresStrait: searchCriteria.profile?.aboriginalOrTorresStrait || false,
                    disabled: searchCriteria.profile?.disabled || false,
                    genderFemale: searchCriteria.profile?.genderFemale || false,
                    genderNonBinary: searchCriteria.profile?.genderNonBinary || false,
                    veteranStatus: searchCriteria.profile?.veteranStatus || false,
                    defencePartner: searchCriteria.profile?.defencePartner || false,
                    workforceReturner: searchCriteria.profile?.workforceReturner || false,
                };

                const affirmativeAction: string[] = [];

                Object.keys(affirmationActionObj).forEach((key) => {
                    if ((affirmationActionObj as any)[key]) {
                        affirmativeAction.push(key);
                    }
                });

                setSearchRestriction({
                    candidateId: searchCriteria.profile?.candidateId || '',
                    seeking: searchCriteria.profile?.seeking || [],
                    affirmativeAction,
                    securityClearance: searchCriteria.profile?.securityClearance || [],
                    auCitizenshipStatus: searchCriteria.profile?.auCitizenshipStatus || [],
                });
            }

            setIsReady(true);
        }
    }, [isReady, searchCriteria]);

    const adminEmailMutation = useAdminEmailMutation();
    const isDisabled = !subject || !emailBody || adminEmailMutation.isLoading;
    if (!isReady) {
        return (
            <Grid item xs={12} sx={{ justifyContent: 'center', minHeight: 200, alignItems: 'center', display: 'flex' }}>
                <CircularProgress />
            </Grid>
        );
    }

    const onSendEmail = async () => {
        if (isDisabled) {
            return;
        }

        try {
            const result = await adminEmailMutation.mutateAsync({
                userIds: selectedIds?.map((v) => v.toString()),
                emailBody,
                subject,
            });

            const failed: any = [];
            const succeeded: any = [];

            result?.forEach((row: any) => {
                if (row.success) {
                    succeeded.push(row);
                }

                if (row.error) {
                    failed.push(row);
                }
            });

            if (!failed.length) {
                dispatch(showSuccessSnackbar('Email sent successfully'));
                setSelectedIds([]);
                setSelectionModel([]);
                setIsModalOpen(false);
                setEmailBody(DEFAULT_EMAIL_CONTENT);
                setSubject('');
            } else {
                dispatch(showErrorSnackbar('Something went wrong when sending email to some recipients. Please try again.'));
                setSelectedIds(failed.map(({ userId }: any) => userId));
                setSelectionModel([]);
                setIsModalOpen(false);
            }
        } catch (error) {
            dispatch(showErrorSnackbar('Something went wrong when sending email. Please try again.'));
        }
    };

    return (
        <>
            {isModalOpen && (
                <Dialog open={true} fullWidth>
                    <DialogTitle sx={{ textAlign: 'center' }}>
                        <Typography fontWeight="bold">Send Email</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ padding: '0.5rem 0' }}>
                            <TextField
                                label="Subject"
                                fullWidth
                                value={subject}
                                onChange={(event) => {
                                    setSubject(event.currentTarget.value);
                                }}
                            />
                        </div>
                        <div style={{ padding: '0.5rem 0' }}>
                            <TextField
                                label="Email Body"
                                fullWidth
                                multiline
                                minRows={5}
                                value={emailBody}
                                onChange={(event) => {
                                    setEmailBody(event.currentTarget.value);
                                }}
                                inputRef={(ref) => {
                                    inputRef.current = ref;
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setIsModalOpen(false);
                                setEmailBody(DEFAULT_EMAIL_CONTENT);
                                setSubject('');
                            }}
                            disabled={adminEmailMutation.isLoading}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" disabled={isDisabled} onClick={onSendEmail}>
                            Send
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Typography variant="h4" p={2}>
                User Admin
            </Typography>
            <Divider></Divider>
            <br />
            {/* Filter Start */}
            <Grid container spacing={2} padding={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item xs="auto">
                    <Button variant="contained" onClick={handleClickCriteriaOpen}>
                        {!hasSkillsSearch ? 'Filter by skill or experience' : 'Select another skill or experience'}
                    </Button>
                </Grid>

                {/* Row 1 */}
                <Grid item xs={12}>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            rowGap: '10px',
                            columnGap: '10px',
                        }}
                    >
                        {Object.keys(searchCriteria)
                            .map((key) => {
                                const section = (searchCriteria as any)[key];
                                if (key === 'profile') {
                                    return Object.keys(section)
                                        .filter((subkey) => section[subkey] === true || section[subkey].length > 0)
                                        .map((subkey) => {
                                            const query = section[subkey];
                                            return {
                                                label: `Profile '${subkey} = ${query}'`,
                                                keys: [key, subkey],
                                            };
                                        });
                                } else if (key === 'geography') {
                                    return Object.keys(section).map((subkey) => {
                                        // const query = section[subkey];
                                        const geoLookup = geography.find((geo) => geo.id === subkey);
                                        return {
                                            label: `Location ${geoLookup?.display}`,
                                            keys: [key, subkey],
                                        };
                                    });
                                } else if (key === 'skills') {
                                    return Object.keys(section).map((subkey) => {
                                        const query = section[subkey];
                                        const skillsLookup = skills.find((skill) => skill.id === subkey);
                                        return {
                                            label: `${query?.modifier}: ${query?.value || ''} ${skillsLookup?.display}`,
                                            keys: [key, subkey],
                                        };
                                    });
                                }
                                return undefined;
                            })
                            .flat()
                            .filter((i) => !!i)
                            .map((result) => (
                                <Chip
                                    key={result?.keys.join('_')}
                                    label={result?.label}
                                    className="searchChip"
                                    onDelete={() => handleDeleteSearchChip(result?.keys || ['', ''])}
                                />
                            ))}
                    </div>
                </Grid>

                {/* Row 2 */}
                <Grid item xs={12}>
                    <Typography variant="grayText">Restrict my search to:</Typography>
                </Grid>
                <Grid item xs={1.5}>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="candidateId">Candidate Id</InputLabel>
                        <OutlinedInput
                            id="candidateId"
                            name="candidateId"
                            label="Candidate Id"
                            value={candidateId}
                            onChange={handleCandidateIdChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearchClick} edge="end">
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            }
                            fullWidth
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2.5}>
                    <FormControl fullWidth>
                        <InputLabel id="security-clearance-label">Security Clearance</InputLabel>
                        <Select
                            labelId="security-clearance-label"
                            multiple
                            name="securityClearance"
                            value={searchRestriction.securityClearance}
                            onChange={handleRestrictionChange}
                            input={<OutlinedInput label="Security Clearance" />}
                            renderValue={(selected) => selected.map((value) => mapValueToLabel(securityClearanceDropdown, value as string)).join(', ')}
                            MenuProps={MenuProps}
                        >
                            {securityClearanceDropdown.map((data, key) => (
                                <CustomMenuItem key={key} value={data.value}>
                                    <Checkbox checked={(searchRestriction.securityClearance || []).indexOf(data.value) > -1} />
                                    <CustomListItemText primary={data.label} />
                                </CustomMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2.5}>
                    <FormControl fullWidth>
                        <InputLabel id="citizenship-status-label">Australian Citizenship Status</InputLabel>
                        <Select
                            labelId="citizenship-status-label"
                            multiple
                            name="auCitizenshipStatus"
                            value={searchRestriction.auCitizenshipStatus}
                            onChange={handleRestrictionChange}
                            input={<OutlinedInput label="Australian Citizenship Status" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {auCitizenshipStatusDropdown.map((data) => (
                                <CustomMenuItem key={data.key} value={data.value}>
                                    <Checkbox checked={(searchRestriction.auCitizenshipStatus || []).indexOf(data.value) > -1} />
                                    <CustomListItemText primary={data.value} />
                                </CustomMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2.5}>
                    <FormControl fullWidth>
                        <InputLabel id="seeking-label">Employment Model</InputLabel>
                        <Select
                            labelId="seeking-label"
                            multiple
                            name="seeking"
                            value={searchRestriction.seeking}
                            onChange={handleRestrictionChange}
                            input={<OutlinedInput label="Employment Model" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {seekingDropdown.map((value, key) => (
                                <CustomMenuItem key={key} value={value}>
                                    <Checkbox checked={(searchRestriction.seeking || []).indexOf(value) > -1} />
                                    <CustomListItemText primary={value} />
                                </CustomMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2.5}>
                    <FormControl fullWidth>
                        <InputLabel id="affirmative-action-label">Affirmative Action</InputLabel>
                        <Select
                            labelId="affirmative-action-label"
                            multiple
                            name="affirmativeAction"
                            value={searchRestriction.affirmativeAction}
                            onChange={handleRestrictionChange}
                            input={<OutlinedInput label="Affirmative Action" />}
                            renderValue={(selected) => selected.map((value) => mapValueToLabel(affirmativeActionFilter, value as string)).join(', ')}
                            MenuProps={MenuProps}
                        >
                            {affirmativeActionFilter.map((data) => (
                                <CustomMenuItem key={data.key} value={data.value}>
                                    <Checkbox checked={(searchRestriction.affirmativeAction || []).indexOf(data.value) > -1} />
                                    <CustomListItemText primary={data.label} />
                                </CustomMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {/* Filter End */}
            {/* Buttons Start */}
            <Grid container spacing={3} padding={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                {/* Row 1 */}
                <Grid item xs="auto">
                    <Button
                        variant="contained"
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                        disabled={!selectedIds.length}
                    >
                        EMAIL CANDIDATE(S)
                    </Button>
                </Grid>
                {/* <Grid item xs="auto">
                    <Button variant="outlined">Refresh search</Button>
                </Grid> */}
                <Grid item xs="auto">
                    <Button variant="outlined" onClick={handleClearSearch}>
                        Clear search
                    </Button>
                </Grid>
            </Grid>
            {/* Buttons End */}
            {/* Table Start */}
            <Grid container spacing={2} padding={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                {/* Row 1 */}
                <Grid item xs={12}>
                    <SearchResultsTable
                        columns={searchCriteria}
                        rows={searchResults}
                        skills={skills}
                        geography={geography}
                        selectionModel={selectionModel}
                        setSelectionModel={setSelectionModel}
                        setSearchCriteria={setSearchCriteria}
                        searchCriteriaObject={{ ...searchCriteria, ...tempSearchCriteria }}
                        searchBy={'admin'}
                        setSelectedIds={setSelectedIds}
                    />
                </Grid>
            </Grid>
            {/* Table End */}
            <br />
            {/* Criteria Modal Start */}
            <Dialog open={criteriaModalOpen} onClose={handleCriteriaClose} fullWidth={true} maxWidth={'md'}>
                <DialogTitle>Add Search Criteria</DialogTitle>
                <DialogContent style={{ height: '450px' }}>
                    <Grid sx={{ flexGrow: 1 }} container spacing={2} padding={0} justifyContent="flex-start" alignItems="center">
                        <Grid item xs={12}>
                            <div>
                                {/* toggle  */}
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <FormControlLabel
                                        value="Keyword Search"
                                        control={
                                            <Switch
                                                checked={criteriaType === 'HIERARCHY'}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        handleCriteriaChange(null, 'HIERARCHY');
                                                    } else {
                                                        handleCriteriaChange(null, 'SKILLSET');
                                                    }
                                                }}
                                                color="default"
                                            />
                                        }
                                        label="Keyword Search"
                                        labelPlacement="start"
                                    />
                                    <p className="ml-1">Skills Families</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} p={0}>
                            <Divider></Divider>
                        </Grid>

                        {criteriaType === 'SKILLSET' && (
                            <Grid item style={{ width: '80%' }}>
                                <FormControl component="fieldset" fullWidth>
                                    <Autocomplete
                                        loadingText="Type a keyword to find associated skills & experiences"
                                        loading={!searchString?.trim()}
                                        multiple
                                        id="auto-complete"
                                        options={getAutocomplete(skills)}
                                        getOptionLabel={(option: AutocompleteType) => `${option.title} ${getKeywordsString(option.keywords)}`}
                                        autoComplete={true}
                                        filterOptions={
                                            searchString?.trim()
                                                ? undefined
                                                : () => {
                                                      return [];
                                                  }
                                        }
                                        disableCloseOnSelect
                                        includeInputInList
                                        inputValue={searchString}
                                        onInputChange={(event, value) => {
                                            if (event.type === 'change') {
                                                setSearchString(value);
                                            }
                                        }}
                                        onChange={(event, value) => handleAutoCompleteChange(value)}
                                        clearOnBlur={false}
                                        isOptionEqualToValue={(option, value) => {
                                            return option.id === value.id;
                                        }}
                                        renderOption={(props, option, { selected }) => {
                                            return (
                                                <li
                                                    {...props}
                                                    key={option.id}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        minHeight: 36,
                                                        padding: 0,
                                                    }}
                                                >
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        checked={selected}
                                                        style={{ marginRight: 8, marginBottom: 4 }}
                                                    />
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            flexWrap: 'wrap',
                                                            rowGap: '0.25rem',
                                                            columnGap: '0.25rem',
                                                        }}
                                                    >
                                                        <Typography style={{ fontSize: '1rem', marginRight: 16 }}>{option.title}</Typography>
                                                        {option.keywords.map((item: any, i: number) => (
                                                            <Chip key={i} label={`${item}`} style={{ padding: 0, height: '1.5rem', pointerEvents: 'none' }} />
                                                        ))}
                                                    </div>
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => {
                                            return (
                                                <TextField {...params} variant="filled" sx={{ m: 0, minWidth: 250 }} autoFocus label="Skill or Experience" />
                                            );
                                        }}
                                        PaperComponent={CustomPaper}
                                    />
                                </FormControl>
                            </Grid>
                        )}

                        {criteriaType === 'HIERARCHY' && (
                            <>
                                <Grid item style={{ width: '20%' }}>
                                    <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                        <InputLabel variant="outlined" htmlFor="select-industry">
                                            Level 1
                                        </InputLabel>
                                        <Select
                                            label="Industry"
                                            name="industry"
                                            onChange={(event) => handleDropdownChange(0, event.target.value)}
                                            value={selected[0]}
                                            inputProps={{ id: 'select-industry' }}
                                        >
                                            {skills
                                                .filter((skill) => (skill.parents || []).length === 0)
                                                .map((record, idx) => (
                                                    <MenuItem key={idx} value={record.id}>
                                                        {record.display}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ width: '20%' }}>
                                    {selected[0] && (
                                        <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                            <InputLabel variant="outlined" htmlFor="select-category">
                                                Level 2
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                label="Category"
                                                name="category"
                                                onChange={(event) => handleDropdownChange(1, event.target.value)}
                                                value={selected[1]}
                                                inputProps={{ id: 'select-category' }}
                                            >
                                                {skills
                                                    .filter((skill) => skill.parents?.includes(selected[0]))
                                                    .map((record, idx) => (
                                                        <MenuItem key={idx} value={record.id}>
                                                            {record.display}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                                <Grid item style={{ width: '20%' }}>
                                    {selected[1] && (
                                        <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                            <InputLabel variant="outlined" htmlFor="select-subcategory">
                                                Level 3
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                label="Sub-category"
                                                name="subcategory"
                                                onChange={(event) => handleDropdownChange(2, event.target.value)}
                                                value={selected[2]}
                                                inputProps={{ id: 'select-subcategory' }}
                                            >
                                                {skills
                                                    .filter((skill) => skill.parents?.includes(selected[1]))
                                                    .map((record, idx) => (
                                                        <MenuItem key={idx} value={record.id}>
                                                            {record.display}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                                <Grid item style={{ width: '20%' }}>
                                    {selected[2] && (
                                        <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                            <InputLabel variant="outlined" htmlFor="select-skillset">
                                                SKILL or EXPERIENCE
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                multiple
                                                label="SKILL or EXPERIENCE"
                                                name="skillset"
                                                onChange={(event) => handleDropdownChange(3, event.target.value)}
                                                // value={selected[3]}
                                                value={selectedSkills}
                                                renderValue={(selected: any) => {
                                                    return selected.map((item: string) => {
                                                        return skills.find((skill) => skill.id === item)?.display;
                                                    });
                                                }}
                                                input={<OutlinedInput label="SKILL or EXPERIENCE" />}
                                                inputProps={{ id: 'select-skillset' }}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            minHeight: 'auto',
                                                            maxHeight: 'auto',
                                                            minWidth: 250,
                                                            maxWidth: 1200,
                                                            overflowX: 'auto',
                                                        },
                                                    },
                                                }}
                                            >
                                                {skills
                                                    .filter((skill) => skill.parents?.includes(selected[2]))
                                                    .map((record: any, idx) => (
                                                        <MenuItem
                                                            key={idx}
                                                            value={record.id}
                                                            style={{
                                                                height: 36,
                                                            }}
                                                        >
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                checked={selected[3].includes(record.id)}
                                                                style={{ marginRight: 8 }}
                                                            />
                                                            {record.display}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                            </>
                        )}

                        <Grid
                            item
                            style={{
                                width: '20%',
                                marginTop: 'auto',
                            }}
                        >
                            <FormControl component="fieldset" fullWidth>
                                <InputLabel variant="filled" htmlFor="select-modifier">
                                    Desirability
                                </InputLabel>
                                <Select
                                    name="modifier"
                                    label="Desirability"
                                    onChange={handleDesirabilityChange}
                                    variant="filled"
                                    value={modifier}
                                    className="skillsDropdown"
                                    inputProps={{ id: 'select-modifier' }}
                                    disabled={!selectedSkill}
                                >
                                    {searchAttributeModifier.map((record, idx) => (
                                        <MenuItem key={idx} value={record.value}>
                                            {record.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} />

                        {(selectedSkill || selected[3]) && (
                            <>
                                {selectedSkill?.map((item) => {
                                    return (
                                        item?.seekerHelpText && (
                                            <Grid item xs={12}>
                                                <br />
                                                <FormControl component="fieldset">
                                                    <Typography variant="body1">{item?.seekerHelpText}</Typography>
                                                </FormControl>
                                            </Grid>
                                        )
                                    );
                                })}

                                {/* We disable the experience bracketing that is displayed on "mandatory" for now. We won't need that until we have thousands of candidates and employers want a senior or junior outcome. Comment code below if you want to enable experience bracketing. */}

                                {/* <Grid item xs={12}>
                                    {modifier === 'MANDATORY' && (
                                        <FormControl component="fieldset" sx={{}}>
                                            <FormLabel component="legend">Experience (years):</FormLabel>

                                            <RadioGroup row name="experience" value={experience} onChange={handleExpChange}>
                                                <FormControlLabel value="ANY" control={<Radio />} label="Any" />
                                                {Object.keys(selectedSkill?.expFramework || {}).map((exp, idx) => (
                                                    <FormControlLabel
                                                        key={idx}
                                                        value={exp}
                                                        control={<Radio />}
                                                        label={(selectedSkill?.expFramework || {})[exp].display || ''}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    )}
                                </Grid> */}
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCriteriaClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleAddSearch} disabled={!selectedSkill?.length || !modifier} variant="contained">
                        Add search criteria
                    </Button>
                </DialogActions>
            </Dialog>{' '}
            {/* Criteria Modal End */}
        </>
    );
};

export default AdminUsers;
