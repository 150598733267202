import {
    Alert,
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useHistoryBack } from '../../../../hooks/useHistoryBack';
import { auCitizenshipStatusDropdown, employmentModalDropdown, securityClearanceDropdown2, workingArrangementDropdown } from '../../../../data/dropDownData';
import { CustomListItemText, CustomMenuItem } from '../../../../components/global';
import { Errors, IDescription, ISettings } from '../../../../types/shortlist';
import { useSelector } from 'react-redux';
import { selectProfileData } from '../../../../redux/selectors/profileSelector';
import Editor from '../../../../components/RichTextEditor';
import { descriptionPermission } from '../../../../utils/permission';
import { selectIsUserAdmin } from '../../../../redux/selectors/userSelector';
import { useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const JobViewDescription = ({
    values,
    setValue,
    save,
    errors,
    makingJobPublic = false,
}: {
    values?: any;
    setValue?: (data: any) => void;
    validate?: () => void;
    save?: any;
    errors?: Errors;
    makingJobPublic: boolean;
}) => {
    const descriptionErrors = errors?.description || [];
    const description = values.description as IDescription;
    const settings = values.settings as ISettings;

    const profileData = useSelector(selectProfileData);
    const isOwner = settings.owner === profileData?.userId;
    let isAuthor;
    if (profileData.userId) {
        isAuthor = settings?.sharedTo.includes(profileData?.userId);
    }
    const isAdmin = useSelector(selectIsUserAdmin);
    const historyBack = useHistoryBack('/my-jobs');
    const titleRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.focus();
        }
    }, []);

    const handleChange = (event: any) => {
        const {
            target: { name, value },
        } = event;
        setValue && setValue({ target: { name, value } });
    };

    const handleSelectChange = (event: any) => {
        const {
            target: { name, value },
        } = event;
        handleChange({ target: { name, value } });
    };

    const renderOptional = (name: string) => {
        let data = '';
        if (settings?.status === 1 || settings?.status === 2) {
            const required = ['title'];
            if (!required.includes(name)) {
                data = '(optional)';
            }
        }

        if (settings?.status === 3 || settings?.status === 4) {
            const required = ['title', 'country', 'city', 'startDate', 'state', 'roleDescription', 'keyRequirement'];
            if (!required.includes(name)) {
                data = '(optional)';
            }
        }
        return data;
    };

    const handleSecurityClearanceChange = (event: any) => {
        const {
            target: { value },
        } = event;
        const newSecurityClearance = description.securityClearance || [];
        if (newSecurityClearance.includes(value)) {
            const index = newSecurityClearance.indexOf(value);
            newSecurityClearance.splice(index, 1);
            handleChange({ target: { name: 'securityClearance', value: newSecurityClearance } });
            return;
        }
        handleChange({ target: { name: 'securityClearance', value: [value] } });
    };

    const handleCitizenshipStatusChange = (event: any) => {
        const {
            target: { value },
        } = event;
        const newCitizenshipStatus = description.citizenshipStatus || [];
        if (newCitizenshipStatus.includes(value)) {
            const index = newCitizenshipStatus.indexOf(value);
            newCitizenshipStatus.splice(index, 1);
            handleChange({ target: { name: 'securityClearance', value: newCitizenshipStatus } });
            return;
        }
        handleChange({ target: { name: 'citizenshipStatus', value: [value] } });
    };
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                    <Alert icon={false}>
                        <Typography variant="body1" fontSize={18}>
                            This information describes your requirements for the job and will be visible to candidates once the job is advertised.
                        </Typography>
                    </Alert>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField
                        ref={titleRef}
                        disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                        fullWidth
                        label="Job / ShortList Title "
                        required={true}
                        error={!!descriptionErrors?.find((item) => item.name === 'title') || false}
                        name="title"
                        onChange={handleChange}
                        value={description?.title}
                        inputProps={{ maxLength: '90' }}
                    />
                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                    <FormControl fullWidth>
                        <InputLabel id="working-arrangement-label" error={!!descriptionErrors?.find((item) => item.name === 'workingArrangement') || false}>
                            Preferred Working Arrangements {renderOptional('workingArrangement')}
                        </InputLabel>
                        <Select
                            disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                            labelId="working-arrangement-label"
                            multiple
                            name="workingArrangement"
                            value={description?.workingArrangement || []}
                            placeholder="List all the working arrangements that may be possible for this job"
                            onChange={handleSelectChange}
                            input={
                                <OutlinedInput
                                    label={'Preferred Working Arrangements' + renderOptional('workingArrangement')}
                                    placeholder="List all the working arrangements that may be possible for this job"
                                />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <Typography variant="grayText">List all the working arrangements that may be possible for this job</Typography>;
                                }
                                return selected.join(', ');
                            }}
                            error={!!descriptionErrors?.find((item) => item.name === 'workingArrangement') || false}
                        >
                            <MenuItem disabled value="">
                                <Typography variant="grayText">List all the working arrangements that may be possible for this job</Typography>
                            </MenuItem>
                            {workingArrangementDropdown.map((data, key) => (
                                <CustomMenuItem key={key} value={data.value}>
                                    <Checkbox checked={(description?.workingArrangement || []).indexOf(data.value) > -1} />
                                    <CustomListItemText primary={data.label} />
                                </CustomMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <FormControl fullWidth>
                        <InputLabel id="employment-modal-label" error={!!descriptionErrors?.find((item) => item.name === 'workingArrangement') || false}>
                            Preferred Employment Models {renderOptional('employmentModal')}
                        </InputLabel>
                        <Select
                            disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                            labelId="employment-modal-label"
                            multiple
                            name="employmentModal"
                            value={description?.employmentModal || []}
                            onChange={handleSelectChange}
                            input={<OutlinedInput label={'Preferred Employment Models' + renderOptional('employmentModal')} />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value: string) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            error={!!descriptionErrors?.find((item) => item.name === 'employmentModal') || false}
                        >
                            <MenuItem disabled value="">
                                <Typography variant="grayText"> List all the employment models that may be possible for this job</Typography>
                            </MenuItem>
                            {employmentModalDropdown.map((data, key) => (
                                <CustomMenuItem key={key} value={data.value}>
                                    <Checkbox checked={(description?.employmentModal || []).indexOf(data.value) > -1} />
                                    <CustomListItemText primary={data.label} />
                                </CustomMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <FormControl fullWidth>
                        <InputLabel id="citizenship-status-label" error={!!descriptionErrors?.find((item) => item.name === 'citizenshipStatus') || false}>
                            Citizenship Status{renderOptional('citizenshipStatus')}
                        </InputLabel>
                        <Select
                            disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                            labelId="citizenship-status-label"
                            name="citizenshipStatus"
                            value={description?.citizenshipStatus || []}
                            onChange={handleCitizenshipStatusChange}
                            input={<OutlinedInput label={'Citizenship Status' + renderOptional('citizenshipStatus')} />}
                            error={!!descriptionErrors?.find((item) => item.name === 'citizenshipStatus') || false}
                            renderValue={(selected) => (
                                <Chip size="small" label={auCitizenshipStatusDropdown.find((item) => item.value === `${selected}`)?.label} />
                            )}
                        >
                            {auCitizenshipStatusDropdown.map((data) => (
                                <CustomMenuItem key={data.key} value={data.value}>
                                    <Radio checked={(description?.citizenshipStatus || []).indexOf(data.value) > -1} />
                                    <CustomListItemText primary={data.label} />
                                </CustomMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <FormControl fullWidth>
                        <InputLabel id="security-clearance-label" error={!!descriptionErrors?.find((item) => item.name === 'securityClearance') || false}>
                            Minimum Security Clearance {renderOptional('securityClearance')}
                        </InputLabel>
                        <Select
                            disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                            labelId="security-clearance-label"
                            name="securityClearance"
                            value={description?.securityClearance || []}
                            onChange={handleSecurityClearanceChange}
                            input={<OutlinedInput label={'Minimum Security Clearance' + renderOptional('securityClearance')} />}
                            renderValue={(selected) => <Chip size="small" label={selected} />}
                            error={!!descriptionErrors?.find((item) => item.name === 'securityClearance') || false}
                        >
                            {securityClearanceDropdown2.map((data, key) => (
                                <CustomMenuItem key={key} value={data.value}>
                                    <Radio checked={(description?.securityClearance || []).indexOf(data.value) > -1} />
                                    <CustomListItemText primary={data.label} />
                                </CustomMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                    <TextField
                        disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                        fullWidth
                        label={`Country ${renderOptional('country')}`}
                        name="country"
                        required={settings?.status === 3 || settings?.status === 4}
                        onChange={handleChange}
                        value={description?.country}
                        error={!!descriptionErrors?.find((item) => item.name === 'country') || false}
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <TextField
                        disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                        fullWidth
                        label={`State / County / Province ${renderOptional('state')}`}
                        name="state"
                        onChange={handleChange}
                        required={settings?.status === 3 || settings?.status === 4}
                        value={description?.state}
                        error={!!descriptionErrors?.find((item) => item.name === 'state') || false}
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <TextField
                        disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                        fullWidth
                        label={`City / Town / Suburb ${renderOptional('city')}`}
                        name="city"
                        onChange={handleChange}
                        required={settings?.status === 3 || settings?.status === 4}
                        value={description?.city}
                        error={!!descriptionErrors?.find((item) => item.name === 'city') || false}
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <TextField
                        disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                        fullWidth
                        label={`Post Code / Zip Code ${renderOptional('postcode')}`}
                        name="postcode"
                        onChange={handleChange}
                        required={settings?.status === 3 || settings?.status === 4}
                        value={description?.postcode}
                        error={!!descriptionErrors?.find((item) => item.name === 'postcode') || false}
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <TextField
                        disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                        fullWidth
                        label={`Start Date ${renderOptional('startDate')}`}
                        name="startDate"
                        onChange={handleChange}
                        required={settings?.status === 3 || settings?.status === 4}
                        value={description?.startDate}
                        error={!!descriptionErrors?.find((item) => item.name === 'startDate') || false}
                    />
                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                    <TextField
                        disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                        fullWidth
                        label={`End Date ${renderOptional('endDate')}`}
                        name="endDate"
                        onChange={handleChange}
                        value={description?.endDate}
                        error={!!descriptionErrors?.find((item) => item.name === 'endDate') || false}
                    />
                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                    <TextField
                        disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                        fullWidth
                        label={`Salary Range ${renderOptional('salaryRange')}`}
                        name="salaryRange"
                        onChange={handleChange}
                        value={description?.salaryRange}
                        error={!!descriptionErrors?.find((item) => item.name === 'salaryRange') || false}
                    />
                </Grid>
                {/* <TextField
                    disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                    fullWidth
                    label={`Potential Contract Extensions (if any) ${renderOptional('contractExtension')}`}
                    name="contractExtension"
                    onChange={handleChange}
                    value={description?.contractExtension}
                    error={!!descriptionErrors?.find((item) => item.name === 'contractExtension') || false}
                /> */}
                <Grid item xs={12} md={3} lg={3}>
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Closing Date"
                                value={description.closingDate ? dayjs(description.closingDate, 'DD/MM/YYYY') : null}
                                onChange={(date) => handleChange({ target: { name: 'closingDate', value: dayjs(date).format('DD/MM/YYYY') } })}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Editor
                        name="roleDescription"
                        required
                        disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                        value={description?.roleDescription?.editorState}
                        label={`Role Description ${renderOptional('roleDescription')}`}
                        onChange={handleChange}
                        placeholder="Summarise the role."
                        error={!!descriptionErrors?.find((item) => item.name === 'roleDescription') || false}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Editor
                        name="keyRequirement"
                        required
                        disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                        value={description?.keyRequirement?.editorState}
                        label={`Key Requirement ${renderOptional('keyRequirement')}`}
                        onChange={handleChange}
                        placeholder="Summarise the most important requirements for candidates to be considered for this role."
                        error={!!descriptionErrors?.find((item) => item.name === 'keyRequirement') || false}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Editor
                        name="additionalDescriptionNotes"
                        disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                        value={description?.additionalDescriptionNotes?.editorState}
                        label={`Additional Notes ${renderOptional('additionalDescriptionNotes')}`}
                        onChange={handleChange}
                        placeholder="Add any notes to further assist the candidate in understanding the role."
                        error={!!descriptionErrors?.find((item) => item.name === 'additionalDescriptionNotes') || false}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Editor
                        name="aboutCompany"
                        disabled={descriptionPermission({ isAdmin, isOwner, isAuthor })}
                        value={description?.aboutCompany?.editorState}
                        label={`About Company ${renderOptional('aboutCompany')}`}
                        onChange={handleChange}
                        placeholder="Details about Company"
                        error={!!descriptionErrors?.find((item) => item.name === 'aboutCompany') || false}
                    />
                </Grid>
            </Grid>
            {!descriptionPermission({ isAdmin, isOwner, isAuthor }) && (
                <Grid
                    sx={{ flexGrow: 1, pl: 3, pt: 2 }}
                    container
                    spacing={2}
                    padding={1}
                    alignContent="flex-end"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item sm={1}>
                        <Button variant="outlined" onClick={() => historyBack()}>
                            cancel
                        </Button>
                    </Grid>
                    <Grid item sm={1}>
                        <Button variant="contained" onClick={() => save && save('description')}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default JobViewDescription;
