import { getInstance } from '../../services/fetch';
import { AppDispatch } from '../store';
import { showSuccessSnackbar, showErrorSnackbar } from './snackbarAction';
import { Job } from '../../types/job';
import dayjs from 'dayjs';

export const getJobs = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: Job[]; error: string }>(`/employer/job`);
        const payload = response.data;
        dispatch({
            type: 'SET_JOBS',
            payload,
        });
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while getting the Jobs`));
    }
};

export const getJob = (uuid: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: Job; error: string }>(`/employer/job/${uuid}`);
        const payload = response.data;
        dispatch({
            type: 'SET_SELECTED_JOB',
            payload,
        });
        return response;
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while getting the Job`));
        return e;
    }
};
export const createNewJob = () => async (dispatch: AppDispatch) => {
    try {
        const date = dayjs().format('YYMMDD');
        const response = await getInstance().post<{ data: Job[]; error: string }, any>(`/employer/job/new`, {
            id: date,
        });
        if (response.error) {
            dispatch(showErrorSnackbar(`It seems you haven't been assigned to any Organisations yet, Please contact Admin`));
            return {};
        }
        const payload = response.data;
        dispatch({
            type: 'SET_SELECTED_JOB',
            payload,
        });
        return payload;
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while creating new Job`));
    }
};

export const applyJob = (uuid: string, additionalInformation: string) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().post<{ error: string }, any>(`/employer/job/apply/${uuid}`, { additionalInformation });
        dispatch(showSuccessSnackbar('Applied successfully!'));
        dispatch(getJob(uuid));
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while applying the Job`));
    }
};

export const upsertJob = (job: Job, message: string) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().post<{ error: string }, any>(`/employer/job`, job);
        await getJobs()(dispatch);
        if (!message) {
            dispatch(showSuccessSnackbar('Job has been upserted successfully'));
        } else {
            dispatch(showSuccessSnackbar(message));
        }
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while upserting the Job`));
    }
};

export const deleteJob = (uuid: string) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().delete<{ error: string }, any>(`/employer/job/${uuid}`);
        await getJobs()(dispatch);
        dispatch(showSuccessSnackbar('Job deleted successfully!'));
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while deleting the Job`));
    }
};
