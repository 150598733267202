import { Typography, Link as StyledLink, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getInstance } from '../../../services/fetch';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganisations } from '../../../redux/actions/organisationsAction';
import { selectOrganisationsData } from '../../../redux/selectors/organisationsSelector';

const JobList: React.FC = () => {
    const dispatch = useDispatch();
    const organizations = useSelector(selectOrganisationsData);
    const [pageSize, setPageSize] = useState<number>(100);
    useEffect(() => {
        dispatch(getOrganisations());
    }, [dispatch]);
    const JobQuery = useQuery(
        ['job'],
        async () => {
            const response = await getInstance().get<{ data: any; error: string }>(`/seeker/job`);
            if (response.error) {
                throw new Error(response.error);
            }
            return response.data;
        },
        {
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
            renderCell: (params: any) => (
                <Link
                    to={`/jobs/${params.row.uuid}`}
                    state={{
                        from: location.pathname,
                    }}
                >
                    <StyledLink component="span">{params.row.id}</StyledLink>
                </Link>
            ),
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
            renderCell: (params: any) => <Typography>{params.row.title}</Typography>,
        },
        {
            field: 'organization',
            headerName: 'Organisations',
            renderCell: (params: any) => <Typography>{(organizations || [])?.find((item) => item.id === params.row.organization)?.name}</Typography>,
            flex: 1,
        },
        {
            field: 'city',
            headerName: 'City',
            flex: 1,
        },
        {
            field: 'state',
            headerName: 'State',
            flex: 1,
        },
        {
            field: 'country',
            headerName: 'Country',
            flex: 1,
        },
        {
            field: 'minSecClearance',
            headerName: 'Min. Sec. Clearance',
            flex: 1,
            renderCell: (params: any) => {
                const securityClearance = params.row.securityClearance || [];
                if (Array.isArray(securityClearance)) {
                    return securityClearance?.map((item: string) => <Typography key={item}>{item}</Typography>);
                } else {
                    return <Typography>{securityClearance}</Typography>;
                }
            },
        },
    ];

    const handlePageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
    };

    return (
        <>
            <Grid container xs={12} pl={2} pr={2}>
                <Grid mt="2rem" container>
                    <Grid item xs={9}>
                        <Typography variant="h4">Jobs Board</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={'2rem'}>
                    <DataGrid
                        style={{
                            minHeight: 500,
                        }}
                        pageSize={pageSize}
                        onPageSizeChange={handlePageSizeChange}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        autoHeight
                        rows={JobQuery.data || []}
                        columns={columns}
                        rowHeight={38}
                        disableSelectionOnClick
                        getRowClassName={(params) => {
                            if (params.row.hasApplied) {
                                return 'has-applied-job';
                            }
                            return '';
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default JobList;
