export const jobDataKEY = 'JOB_DATA';
export const jobShowSkill = 'SHOW_SKILLS';

export const authRedirectKey = 'AUTH_REDIRECT';

export const skillsAnswersMap = new Map<number, string>([
    [5, 'Very high'],
    [4, 'High'],
    [3, 'Medium'],
    [2, 'Low'],
    [1, 'Very low'],
]);
