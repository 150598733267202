import { useState, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppBar, Box, IconButton, Toolbar, Tooltip, Avatar, Menu, MenuItem, Typography, Button } from '@mui/material';
import { selectProfileData } from '../../redux/selectors/profileSelector';
import { Nav, NavData, PublicNavData } from '../../data/nav';
import { selectIsUserAdmin } from '../../redux/selectors/userSelector';

const settings = ['Account', 'Logout'];

const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
};

const stringAvatar = (name: string) => {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
};

function Header() {
    const profileData = useSelector(selectProfileData);
    const isAdminUser = useSelector(selectIsUserAdmin);

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const [navAnchor, setNavAnchor] = useState<Record<string, null | HTMLElement>>({});

    const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>, item: Nav) => {
        const newNavAnchor = { ...navAnchor };
        newNavAnchor[item.id] = event.currentTarget;
        setNavAnchor(newNavAnchor);
    };
    const handleCloseNavMenu = (item: Nav) => {
        const newNavAnchor = { ...navAnchor };
        newNavAnchor[item.id] = null;
        setNavAnchor(newNavAnchor);
    };
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                        <img src={'/Aukus_W715.png'} height={'48px'} alt="Aukus Logo" />
                        {profileData.wizardCompleted && (
                            <nav className="top-nav">
                                <ul className="top-nav">
                                    {profileData.candidateId === '' && (
                                        <>
                                            {PublicNavData.map((item) => {
                                                if (item.sub?.length) {
                                                    return (
                                                        <li key={item.id} className="top-nav-items">
                                                            <Button onClick={(event) => handleOpenNavMenu(event, item)} className="top-nav-dropdown">
                                                                {/* <i className={`${item.icon} mr-1`}></i> */}
                                                                <Typography variant="body1" color={'white'}>
                                                                    {item.title}
                                                                    {navAnchor[item.id] ? (
                                                                        <i className="fa-solid fa-angle-up ml-1"></i>
                                                                    ) : (
                                                                        <i className="fa-solid fa-angle-down ml-1"></i>
                                                                    )}
                                                                </Typography>
                                                            </Button>
                                                            <Menu
                                                                sx={{ mt: '2.813rem' }}
                                                                id={item.id}
                                                                anchorEl={navAnchor[item.id]}
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                                keepMounted
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                                open={Boolean(navAnchor[item.id])}
                                                                onClose={() => handleCloseNavMenu(item)}
                                                            >
                                                                {item.sub?.length &&
                                                                    item.sub.map((subItem) => {
                                                                        if (subItem.external) {
                                                                            return (
                                                                                <Tooltip key={subItem.id} title={subItem.hover}>
                                                                                    <a
                                                                                        href={subItem.path}
                                                                                        target="_blank"
                                                                                        style={{
                                                                                            color: 'inherit',
                                                                                            textDecoration: 'inherit',
                                                                                        }}
                                                                                        rel="noreferrer"
                                                                                    >
                                                                                        <MenuItem
                                                                                            onClick={() => handleCloseNavMenu(item)}
                                                                                            sx={{ maxWidth: '15rem' }}
                                                                                        >
                                                                                            <i className={`${subItem.ico} mr-1`}></i>
                                                                                            {subItem.label}
                                                                                        </MenuItem>
                                                                                    </a>
                                                                                </Tooltip>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <Tooltip key={subItem.id} title={subItem.hover}>
                                                                                    <MenuItem
                                                                                        key={subItem.id}
                                                                                        component={Link}
                                                                                        to={subItem.path}
                                                                                        onClick={() => handleCloseNavMenu(item)}
                                                                                        sx={{ maxWidth: '15rem' }}
                                                                                    >
                                                                                        <i className={`${subItem.ico} mr-1`}></i>
                                                                                        {subItem.label}
                                                                                    </MenuItem>
                                                                                </Tooltip>
                                                                            );
                                                                        }
                                                                    })}
                                                            </Menu>
                                                        </li>
                                                    );
                                                } else {
                                                    if (item.external) {
                                                        return (
                                                            <li key={item.id} className="top-nav-items">
                                                                <a
                                                                    href={`https://aukusjobs.com${item.path}`}
                                                                    target="_blank"
                                                                    style={{
                                                                        color: 'inherit',
                                                                        textDecoration: 'inherit',
                                                                    }}
                                                                    rel="noreferrer"
                                                                >
                                                                    <MenuItem onClick={() => handleCloseNavMenu(item)} sx={{ maxWidth: '15rem' }}>
                                                                        {/* <i className={`${subItem.ico} mr-1`}></i> */}
                                                                        {item.title}
                                                                    </MenuItem>
                                                                </a>
                                                                {/* <Button className="top-nav-btn">
                                                                    <Typography>{item.title}</Typography>
                                                                </Button> */}
                                                            </li>
                                                        );
                                                    } else {
                                                        return (
                                                            <li key={item.id} className="top-nav-items">
                                                                <Button className="top-nav-btn">
                                                                    {/* <i className={`${item.icon} mr-1`}></i> */}
                                                                    <Typography>{item.title}</Typography>
                                                                </Button>
                                                            </li>
                                                        );
                                                    }
                                                }
                                            })}
                                        </>
                                    )}

                                    {profileData.candidateId !== '' && (
                                        <>
                                            {NavData.map((item) => {
                                                if (!isAdminUser) {
                                                    if (!item.permission.includes(profileData.accountType || '')) {
                                                        return;
                                                    }
                                                    if (item.sub?.length) {
                                                        return (
                                                            <li key={item.id} className="top-nav-items">
                                                                <Button onClick={(event) => handleOpenNavMenu(event, item)} className="top-nav-dropdown">
                                                                    <i className={`${item.icon} mr-1`}></i>
                                                                    <Typography variant="body1" color={'white'}>
                                                                        {item.title}
                                                                        {navAnchor[item.id] ? (
                                                                            <i className="fa-solid fa-angle-up ml-1"></i>
                                                                        ) : (
                                                                            <i className="fa-solid fa-angle-down ml-1"></i>
                                                                        )}
                                                                    </Typography>
                                                                </Button>
                                                                <Menu
                                                                    sx={{ mt: '2.813rem' }}
                                                                    id={item.id}
                                                                    anchorEl={navAnchor[item.id]}
                                                                    anchorOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    keepMounted
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    open={Boolean(navAnchor[item.id])}
                                                                    onClose={() => handleCloseNavMenu(item)}
                                                                >
                                                                    {item.sub?.length &&
                                                                        item.sub.map((subItem) => {
                                                                            if (subItem.external) {
                                                                                return (
                                                                                    <Tooltip key={subItem.id} title={subItem.hover}>
                                                                                        <a
                                                                                            href={subItem.path}
                                                                                            target="_blank"
                                                                                            style={{
                                                                                                color: 'inherit',
                                                                                                textDecoration: 'inherit',
                                                                                            }}
                                                                                            rel="noreferrer"
                                                                                        >
                                                                                            <MenuItem
                                                                                                onClick={() => handleCloseNavMenu(item)}
                                                                                                sx={{ width: '15rem' }}
                                                                                            >
                                                                                                <i className={`${subItem.ico} mr-1`}></i>
                                                                                                {subItem.label}
                                                                                            </MenuItem>
                                                                                        </a>
                                                                                    </Tooltip>
                                                                                );
                                                                            } else {
                                                                                return (
                                                                                    <Tooltip key={subItem.id} title={subItem.hover}>
                                                                                        <MenuItem
                                                                                            key={subItem.id}
                                                                                            component={Link}
                                                                                            to={subItem.path}
                                                                                            onClick={() => handleCloseNavMenu(item)}
                                                                                            sx={{ maxWidth: '15rem' }}
                                                                                        >
                                                                                            <i className={`${subItem.ico} mr-1`}></i>
                                                                                            {subItem.label}
                                                                                        </MenuItem>
                                                                                    </Tooltip>
                                                                                );
                                                                            }
                                                                        })}
                                                                </Menu>
                                                            </li>
                                                        );
                                                    } else {
                                                        return (
                                                            <li key={item.id} className="top-nav-items">
                                                                <Button className="top-nav-btn">
                                                                    <i className={`${item.icon} mr-1`}></i>
                                                                    <Typography>{item.title}</Typography>
                                                                </Button>
                                                            </li>
                                                        );
                                                    }
                                                } else {
                                                    if (item.sub?.length) {
                                                        return (
                                                            <li key={item.id} className="top-nav-items">
                                                                <Button onClick={(event) => handleOpenNavMenu(event, item)} className="top-nav-dropdown">
                                                                    <i className={`${item.icon} mr-1`}></i>
                                                                    <Typography variant="body1" color={'white'}>
                                                                        {item.title}
                                                                        {navAnchor[item.id] ? (
                                                                            <i className="fa-solid fa-angle-up ml-1"></i>
                                                                        ) : (
                                                                            <i className="fa-solid fa-angle-down ml-1"></i>
                                                                        )}
                                                                    </Typography>
                                                                </Button>
                                                                <Menu
                                                                    sx={{ mt: '2.813rem' }}
                                                                    id={item.id}
                                                                    anchorEl={navAnchor[item.id]}
                                                                    anchorOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    keepMounted
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    open={Boolean(navAnchor[item.id])}
                                                                    onClose={() => handleCloseNavMenu(item)}
                                                                >
                                                                    {item.sub?.length &&
                                                                        item.sub.map((subItem) => {
                                                                            if (subItem.external) {
                                                                                return (
                                                                                    <Tooltip key={subItem.id} title={subItem.hover}>
                                                                                        <a
                                                                                            href={subItem.path}
                                                                                            target="_blank"
                                                                                            style={{
                                                                                                color: 'inherit',
                                                                                                textDecoration: 'inherit',
                                                                                            }}
                                                                                            rel="noreferrer"
                                                                                        >
                                                                                            <MenuItem
                                                                                                onClick={() => handleCloseNavMenu(item)}
                                                                                                sx={{ maxWidth: '15rem' }}
                                                                                            >
                                                                                                <i className={`${subItem.ico} mr-1`}></i>
                                                                                                {subItem.label}
                                                                                            </MenuItem>
                                                                                        </a>
                                                                                    </Tooltip>
                                                                                );
                                                                            } else {
                                                                                return (
                                                                                    <Tooltip key={subItem.id} title={subItem.hover}>
                                                                                        <MenuItem
                                                                                            key={subItem.id}
                                                                                            component={Link}
                                                                                            to={subItem.path}
                                                                                            onClick={() => handleCloseNavMenu(item)}
                                                                                            sx={{ maxWidth: '15rem' }}
                                                                                        >
                                                                                            <i className={`${subItem.ico} mr-1`}></i>
                                                                                            {subItem.label}
                                                                                        </MenuItem>
                                                                                    </Tooltip>
                                                                                );
                                                                            }
                                                                        })}
                                                                </Menu>
                                                            </li>
                                                        );
                                                    } else {
                                                        return (
                                                            <li key={item.id} className="top-nav-items">
                                                                <Button className="top-nav-btn">
                                                                    <i className={`${item.icon} mr-1`}></i>
                                                                    <Typography>{item.title}</Typography>
                                                                </Button>
                                                            </li>
                                                        );
                                                    }
                                                }
                                            })}
                                        </>
                                    )}
                                </ul>
                            </nav>
                        )}
                        {profileData.candidateId !== '' && (
                            <>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar {...stringAvatar(`${profileData.firstname} ${profileData.surname}`)} />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '2.813rem' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem
                                            key={setting}
                                            component={Link}
                                            to={setting.toLowerCase()}
                                            onClick={handleCloseUserMenu}
                                            sx={{ maxWidth: '15rem' }}
                                        >
                                            {setting}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Header;
