import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Grid, Divider, Typography, IconButton, Box } from '@mui/material';
import { Organisation } from '../../../types/organisation';
import { selectOrganisationsData } from '../../../redux/selectors/organisationsSelector';
import { showSuccessSnackbar, showWarningSnackbar } from '../../../redux/actions/snackbarAction';
import { deleteAdminOrganisation, getOrganisations, upsertOrganisation } from '../../../redux/actions/organisationsAction';
import Editor from '../../../components/RichTextEditor';
import { clone } from 'ramda';

type LogoType = {
    logo: File | null;
    preview: string | null;
};

const AdminOrganisations = () => {
    const dispatch = useDispatch();
    const organisations = useSelector(selectOrganisationsData);
    // const [selectedRows, setSelectedRows] = useState<Organisation[]>([]);
    const [organisation, setOrganisation] = useState<Organisation>();
    const [addEditModalOpen, setAddEditModalOpen] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [deleteOrg, setDeleteOrg] = useState<Organisation>();
    const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
    const [logo, setLogo] = useState<LogoType>({
        logo: null,
        preview: null,
    });
    const defaultOrg: Organisation = {
        id: '',
        name: '',
        description: '',
        address: '',
    };

    const handleClickAdd = async () => {
        setOrganisation(clone(defaultOrg));
        setEditMode(false);
        setAddEditModalOpen(true);
    };

    const handleConfirmClose = () => {
        setConfirmModalOpen(false);
    };

    const handleClose = () => {
        setAddEditModalOpen(false);
    };

    function handleClickRemove(params: any) {
        setDeleteOrg(params.row);
        setConfirmModalOpen(true);
    }

    async function handleOrgDelete(id: string | undefined) {
        dispatch(deleteAdminOrganisation(id || ''));
        setConfirmModalOpen(false);
        dispatch(showSuccessSnackbar('Deleted successfully!'));
    }

    function handleClickEdit(params: any) {
        const newOrg = clone(params.row as Organisation);
        setOrganisation(newOrg);
        setLogo({ preview: newOrg?.meta?.logo } as LogoType);
        setEditMode(true);
        setTimeout(() => setAddEditModalOpen(true), 175);
    }

    const handleChange = (event: any) => {
        const { name, value } = event.target;

        setOrganisation({
            ...organisation,
            [name]: value,
        } as Organisation);
    };

    async function handleSaveOrganisation() {
        if (!logo.logo && !logo.preview) {
            dispatch(showWarningSnackbar('Please select a logo!'));
        }
        dispatch(upsertOrganisation(organisation as Organisation, logo.logo));
        dispatch(getOrganisations());
        setAddEditModalOpen(false);
    }

    useEffect(() => {
        dispatch(getOrganisations());
    }, [dispatch]);

    // function disableEditButton() {
    //     if (selectedRows.length === 1) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }

    // function disableRemoveButton() {
    //     if (selectedRows.length > 0) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }

    function disableSaveButton() {
        if (organisation && organisation.id && organisation.name) {
            return false;
        } else {
            return true;
        }
    }

    const mappedOrganisationsAdmin = (organisations || []).map((key, idx) => {
        const organisation = organisations[idx];
        return {
            id: organisation.id,
            name: organisation.name,
            description: organisation.description,
            address: organisation.address,
            meta: organisation.meta,
        } as Organisation;
    });

    const columns = [
        { field: 'id', headerName: 'Id', sortable: true, flex: 1 },
        { field: 'name', headerName: 'Name', sortable: true, flex: 2 },
        { field: 'description', headerName: 'Description', sortable: true, flex: 2 },
        { field: 'address', headerName: 'Address', sortable: true, flex: 1 },
        { field: 'actions', headerName: 'Actions', sortable: false, flex: 1, renderCell: renderActions },
    ];

    function renderActions(params: GridRenderCellParams<any>) {
        return mappedOrganisationsAdmin.length >= 1 ? (
            <>
                <IconButton aria-label="Edit" onClick={() => handleClickEdit(params)}>
                    <EditIcon />
                </IconButton>

                <IconButton aria-label="Delete" onClick={() => handleClickRemove(params)}>
                    <DeleteIcon />
                </IconButton>
            </>
        ) : null;
    }

    function handleMetaChange(event: any) {
        const { name, value } = event.target;

        setOrganisation({
            ...organisation,
            meta: {
                ...organisation?.meta,
                [name]: value,
            },
        } as Organisation);
    }

    // function handleLocationChange(event: any) {
    //     const { name, value } = event.target;
    //     const parsedValue = parseFloat(value);
    //     if (name === 'longitude') {
    //         setOrganisation({
    //             ...organisation,
    //             location: {
    //                 ...organisation?.location,
    //                 type: 'Point',
    //                 coordinates: [parsedValue, organisation?.location?.coordinates[1] || 0],
    //             },
    //         } as Organisation);
    //     } else {
    //         setOrganisation({
    //             ...organisation,
    //             location: {
    //                 ...organisation?.location,
    //                 type: 'Point',
    //                 coordinates: [organisation?.location?.coordinates[0] || 0, parsedValue],
    //             },
    //         } as Organisation);
    //     }
    // }

    const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            // generate url for the preview
            const url = URL.createObjectURL(file);
            setLogo({
                logo: file,
                preview: url,
            });
        }
    };
    return (
        <>
            <Grid display={'flex'} justifyContent={'space-between'}>
                <Typography variant="h4" p={2}>
                    Organisations Admin
                </Typography>
                <Button onClick={handleClickAdd} variant="contained" sx={{ marginTop: '10px', marginBottom: '10px' }}>
                    Add <br /> Organisation
                </Button>
            </Grid>
            <Divider></Divider>
            <br />
            <Grid sx={{ flexGrow: 1 }} container spacing={2} padding={1}>
                {/* Row */}
                <Grid item xs={12}>
                    <Typography variant="h5">All organisations:</Typography>
                </Grid>
                {/* Row */}
                <Grid item xs={12}>
                    <DataGrid
                        checkboxSelection={false}
                        disableSelectionOnClick
                        rows={mappedOrganisationsAdmin}
                        columns={columns}
                        pageSize={15}
                        rowsPerPageOptions={[15]}
                        autoHeight
                        density="compact"
                        disableExtendRowFullWidth={false}
                        disableColumnFilter
                        disableColumnSelector
                        // onSelectionModelChange={(ids) => {
                        //     const selectedIDs = new Set(ids);
                        //     const selectedRows = mappedOrganisationsAdmin.filter((row) => selectedIDs.has(row.id));
                        //     setSelectedRows(selectedRows);
                        // }}
                    />
                </Grid>

                {/* Row */}
                {/* <Grid item xs={1}>
                    <Button onClick={handleClickAdd} variant="outlined">Add</Button>
                </Grid> */}
                {/* <Grid item xs={1}>
                    <Button onClick={handleClickEdit} disabled={disableEditButton()} variant="outlined">Edit</Button>
                    </Grid>
                <Grid item xs={1}>
                    <Button onClick={handleClickRemove} disabled={disableRemoveButton()} variant="outlined">Remove</Button>
                </Grid>
                <Grid item xs={9}/> */}
                <Grid item xs={11} />
            </Grid>
            <Dialog open={addEditModalOpen} onClose={handleClose} fullWidth maxWidth={'md'}>
                {editMode ? <DialogTitle>Edit Organisation</DialogTitle> : <DialogTitle>Add Organisation</DialogTitle>}
                <Divider></Divider>
                <DialogContent style={{ height: '400px' }}>
                    {organisation && (
                        <>
                            <Grid sx={{ flexGrow: 1 }} container spacing={4} padding={2}>
                                {/* Row */}
                                <Grid item xs={6}>
                                    <Grid mb={4}>
                                        <TextField
                                            disabled={editMode ? true : false}
                                            label="Id"
                                            name="id"
                                            key="id"
                                            value={organisation.id}
                                            onChange={handleChange}
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField label="Name" name="name" key="name" value={organisation.name} onChange={handleChange} required fullWidth />
                                    </Grid>
                                </Grid>

                                <Grid item xs={6} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
                                    {logo.preview && (
                                        <>
                                            <img src={logo.preview} className="organization-logo" />
                                        </>
                                    )}
                                    {
                                        <Button variant="contained" component="label">
                                            Select File
                                            <input hidden type="file" onChange={handleSelectFile} accept="image/png, image/gif, image/jpeg" />
                                        </Button>
                                    }
                                </Grid>

                                {/* Row */}
                                <Grid item xs={12}>
                                    <TextField
                                        label="Description"
                                        name="description"
                                        key="description"
                                        value={organisation.description}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>

                                {/* Row */}
                                <Grid item xs={12}>
                                    <TextField label="Address" name="address" key="address" value={organisation.address} onChange={handleChange} fullWidth />
                                    {/* <APIProvider apiKey="AIzaSyBM0bUHFOHotRJINOtWHr4_axtM4aYexcE">
                                        <MapAutoComplete />
                                    </APIProvider> */}
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <TextField
                                        label="longitude"
                                        name="longitude"
                                        value={organisation.location.coordinates[0]}
                                        onChange={handleLocationChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Latitude"
                                        name="latitude"
                                        value={organisation.location?.coordinates[1]}
                                        onChange={handleLocationChange}
                                        fullWidth
                                    />
                                </Grid> */}

                                <Grid item xs={12}>
                                    <Editor
                                        name="overview"
                                        label="Overview"
                                        value={organisation.meta?.overview?.editorState}
                                        onChange={handleMetaChange}
                                        placeholder="Enter Organisation Overview"
                                    />
                                </Grid>
                            </Grid>
                            <br />
                        </>
                    )}
                    <br />
                </DialogContent>
                <Divider></Divider>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveOrganisation} disabled={disableSaveButton()} variant="contained">
                        {editMode ? 'Save Organisation' : 'Create Organisation'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={confirmModalOpen} onClose={handleConfirmClose} fullWidth maxWidth="sm">
                <DialogTitle>Confirm delete</DialogTitle>
                <Box position="absolute" top={2} right={2}>
                    <IconButton onClick={handleConfirmClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <p>
                        Are you sure you want to delete the <b>{deleteOrg?.name}</b> organisation?
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleConfirmClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={() => handleOrgDelete(deleteOrg?.id)}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>{' '}
            {/*Confirm delete modal*/}
        </>
    );
};

export default AdminOrganisations;
