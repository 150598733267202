import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, CircularProgress, Button, Link, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ProfileOverview } from '../../../types/CandidateProfile';
import { Skill } from '../../../types/skill';
import { getSkillsAnswers, getSkillsFramework } from '../../../redux/actions/skillsAction';
import { selectSkillsData } from '../../../redux/selectors/skillsSelector';
import { getOrganisations } from '../../../redux/actions/organisationsAction';
import { profileReadiness } from '../../../data/dropDownData';
import { mapValueToLabel } from '../../../utils/mapValueToLabel';
import { useQuery } from '@tanstack/react-query';
import { getInstance } from '../../../services/fetch';
import { configContextObject, configureFetch } from '../../../config/ConfigProvider';
import RequestSentModal from '../../../components/RequestSentModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { authRedirectKey } from '../../../constant';
declare interface MappedSkill {
    id: number;
    skillset: string;
    reference: string;
    qualification: string;
    experience: string;
    summary: string;
}

declare interface AddressLine {
    city: string;
    state: string;
    country: string;
    postcode: string;
}

function PublicCandidate() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { candidateId: userId } = useParams();
    const skills = useSelector(selectSkillsData);
    const [requestModal, setRequestModal] = useState(false);

    const profileOverviewQuery = useQuery<ProfileOverview>(
        ['profile-overview', userId],
        async () => {
            const response = await getInstance().get<{ data: any; error: string }>(`/public/candidate/view/${userId}`);

            return {
                ...response.data,
                userId,
            };
        },
        {
            enabled: !!userId,
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    const profileOverview = profileOverviewQuery.data as ProfileOverview;

    useEffect(() => {
        async function fetchData() {
            await configureFetch();
            dispatch(getSkillsFramework());
            dispatch(getSkillsAnswers());
            // dispatch(getGeographyFramework());
            dispatch(getOrganisations());
        }
        fetchData();
    }, [dispatch]);

    if (!profileOverview) {
        return (
            <Grid sx={{ flexGrow: 1, pt: 8, pb: 2 }} container spacing={2} direction="row" display="flex" justifyContent="center">
                {profileOverviewQuery.isLoading ? (
                    <CircularProgress />
                ) : (
                    <div>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                            Error loading profile
                        </Typography>
                    </div>
                )}
            </Grid>
        );
    }

    function getSkillFromRef(ref: string) {
        const skill = skills.find((skill: Skill) => skill.id === ref) || {};
        return skill;
    }

    const mappedSkills = Object.keys(profileOverview.skills || []).flatMap((key, idx) => {
        const answer = profileOverview.skills[key];
        const currSkill = getSkillFromRef(key) as Skill;
        const qualVal = (currSkill?.qualFramework || {})[answer.qualification || '']?.display || '';
        const expVal = (currSkill?.expFramework || {})[answer.experience || '']?.display || '';

        if (!currSkill.enabled) {
            return [];
        }

        return [
            {
                id: idx + 1,
                skillset: currSkill.display,
                reference: key,
                qualification: qualVal,
                experience: expVal,
                summary: answer.summary,
            } as MappedSkill,
        ];
    });

    const combineAddress = (address: Partial<AddressLine>) => {
        const { city, state, country, postcode } = address;
        return [city, state, country, postcode].filter(Boolean).join(', ');
    };

    const handleClose = () => {
        setRequestModal(false);
    };

    const handleSignIn = () => {
        const from = window.location.pathname;
        const data = JSON.stringify({ redirect: `/overview/${userId}`, from });
        localStorage.setItem(authRedirectKey, data);
        navigate('/login');
    };

    return (
        <>
            <Grid>
                <Grid item xs={2}>
                    <Typography variant="h5" p={2} color={'primary'}>
                        <Link href="https://aukusjobs.com">
                            <IconButton color="primary" aria-label="back">
                                <ArrowBackIcon />
                            </IconButton>
                        </Link>
                        Home
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {/* Availability details */}
                    <Box padding={1}>
                        <Grid container display={'flex'} alignItems={'center'} justifyContent={'center'} mb={'0.5rem'}>
                            <Typography color="primary" variant="h6">
                                {' '}
                                <b>Overview</b>
                            </Typography>
                        </Grid>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid sx={{ flexGrow: 1 }} container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Candidate ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography color={'#F38F2F'} sx={{ display: 'inline', fontWeight: '500' }}>
                                            {profileOverview.profile.candidateId}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Location:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography color={'#F38F2F'} sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                            {combineAddress({
                                                city: profileOverview.profile.location?.city,
                                                state: profileOverview.profile.location?.state,
                                                country: profileOverview.profile.location?.country,
                                                postcode: profileOverview.profile.location?.postcode,
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Aust. Security Clearance:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography color={'#F38F2F'} sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                            {profileOverview.profile.securityClearance ? 'Yes' : 'No'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Aust. Citizenship Status:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography
                                            color={'#F38F2F'}
                                            sx={{ display: 'inline', mr: 1, mt: 20, fontWeight: '500' }}
                                            style={{ textTransform: 'capitalize' }}
                                        >
                                            {profileOverview.profile.auCitizenshipStatus}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Nationalities:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography
                                            color={'#F38F2F'}
                                            sx={{ display: 'inline', mr: 1, fontWeight: '500' }}
                                            style={{ textTransform: 'capitalize' }}
                                        >
                                            {(profileOverview.profile.nationality || []).join(', ')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Employment Models:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography color={'#F38F2F'} sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                            {(profileOverview.profile.seeking || []).join(', ')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Mobility:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography color={'#F38F2F'} sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                            {profileOverview.profile.mobilityComment || ''}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Availability:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography color={'#F38F2F'} sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                            {profileOverview.profile.availabilityComment || ''}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Jobseeking Status:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {/* <Chip size="small" label={profileOverview.profile.readiness} color="success" /> */}
                                        <Typography color={'#F38F2F'} sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                            {mapValueToLabel(profileReadiness, profileOverview.profile.readiness || '')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Last Updated:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography
                                            color={'#F38F2F'}
                                            sx={{ display: 'inline', mr: 1, mt: 20, fontWeight: '500' }}
                                            style={{ textTransform: 'capitalize' }}
                                        >
                                            {profileOverview.profile.lastUpdated}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box padding={1}>
                        <Grid container display={'flex'} alignItems={'center'} justifyContent={'center'} mb={'0.5rem'}>
                            <Typography color="primary" variant="h6">
                                {' '}
                                <b>Identity</b>
                            </Typography>
                        </Grid>
                        <Box padding={1} style={{ position: 'relative' }}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Grid
                                        sx={{ flexGrow: 1 }}
                                        container
                                        spacing={1}
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        wrap="wrap"
                                    >
                                        <Grid item xs={4}>
                                            <Typography color={'primary'} sx={{ display: 'inline' }}>
                                                Name:{' '}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography sx={{ display: 'inline', fontWeight: '500', color: 'grey' }}>john doe</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography color={'primary'} sx={{ display: 'inline', mr: 1 }}>
                                                Email:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography sx={{ display: 'inline', mr: 1, fontWeight: '500', color: 'grey' }}>johndoe@aukus.com</Typography>
                                        </Grid>
                                        <Grid item xs={12}></Grid>
                                        <Grid item xs={12}></Grid>
                                        <Grid item xs={12}></Grid>
                                        <Grid item xs={12}></Grid>

                                        <Grid item xs={4}>
                                            <Typography color={'primary'} sx={{ display: 'inline', mr: 1 }}>
                                                Phone Number:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography sx={{ display: 'inline', mr: 1, fontWeight: '500', color: 'grey' }}>98821232112</Typography>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Typography color={'primary'} sx={{ display: 'inline', mr: 1 }}>
                                                LinkedIn URL:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}></Grid>

                                        <Grid item xs={4}>
                                            <Typography color={'primary'} sx={{ display: 'inline', mr: 1 }}>
                                                Attachments:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} />
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Grid className="overlapping-container" display={'flex'} padding={'0.5rem'} flexDirection={'column'}>
                                <Grid width={'100%'} padding={'2rem'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <i className="fa-solid fa-lock fa-2xl" style={{ color: '#1b717b' }}></i>
                                </Grid>
                                <Grid>
                                    <Typography color={'#c15e00'} variant="body1">
                                        You are currently viewing candidate data in public-mode. If you wish to connect with candidates please Sign-In or
                                        subscribe to the platform.
                                    </Typography>
                                </Grid>
                                <br />
                                <Grid display={'flex'} alignItems={'center'} justifyContent={'space-around'} mt={'1rem'}>
                                    <Link href="https://aukusjobs.com/pricing">
                                        <Button variant={'contained'}>Request Subscription</Button>
                                    </Link>

                                    <Button onClick={handleSignIn} variant={'contained'}>
                                        Sign-In
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            {/* Career Summary & Aspirations */}
            <Box padding={1}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={12}>
                                <Typography color={'primary'} variant="h6" sx={{ fontWeight: '500' }}>
                                    Career Summary & Aspirations
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{profileOverview.profile.careerSummary}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>

            <Box padding={1}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid sx={{ flexGrow: 1 }} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={12} sx={{ padding: '0 5px 15px 5px' }}>
                                <div style={{ display: 'inline-block', minWidth: '40%', fontWeight: '500', textAlign: 'left' }}>
                                    <Typography color={'primary'} variant="h6">
                                        Skills or Experience
                                    </Typography>
                                </div>
                                <div style={{ display: 'inline-block', minWidth: '20%', fontWeight: '500', textAlign: 'left' }}>
                                    <Typography color={'primary'} variant="h6">
                                        Experience
                                    </Typography>
                                </div>
                                <div style={{ display: 'inline-block', minWidth: '40%', fontWeight: '500', textAlign: 'left' }}>
                                    <Typography color={'primary'} variant="h6">
                                        Competency / Qualification Level
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                {mappedSkills.map((item) => {
                                    return item.summary ? (
                                        // with summary
                                        <details key={item.id}>
                                            <summary>
                                                <div style={{ width: '40%' }}>{item.skillset}</div>
                                                <div style={{ width: '20%' }}>{item.experience}</div>
                                                <div style={{ width: '40%' }}>{item.qualification}</div>
                                            </summary>
                                            <div className="auk__accordion-body">{item.summary}</div>
                                        </details>
                                    ) : (
                                        // without summary
                                        <div key={item.id} className="auk__accordion">
                                            <div className="auk__accordion-body">
                                                <div style={{ width: '40%' }}>{item.skillset}</div>
                                                <div style={{ width: '20%' }}>{item.experience}</div>
                                                <div style={{ width: '40%' }}>{item.qualification}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <RequestSentModal open={requestModal} handleClose={handleClose} />
        </>
    );
}

export default PublicCandidate;
