import 'react-app-polyfill/stable';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import ConfigProvider from './config/ConfigProvider';
import AuthProvider from './auth/AuthProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            cacheTime: 0,
            staleTime: 0,
        },
    },
});

declare module '@mui/material/styles' {
    interface TypographyVariants {
        grayText: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        grayText?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        grayText: true;
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#1b717a',
            contrastText: '#fff',
        },
        secondary: {
            main: '#051026',
        },
        info: {
            main: '#F38F2F',
            contrastText: '#fff',
        },
    },
    components: {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginTop: 0,
                    height: 0,
                },
            },
        },
    },
    typography: {
        grayText: {
            color: 'gray',
            fontSize: '0.875rem',
        },
    },
});

render(
    <ConfigProvider>
        <BrowserRouter>
            <AuthProvider>
                <Provider store={store}>
                    <ThemeProvider theme={theme}>
                        <QueryClientProvider client={queryClient}>
                            <App />
                        </QueryClientProvider>
                    </ThemeProvider>
                </Provider>
            </AuthProvider>
        </BrowserRouter>
    </ConfigProvider>,
    document.getElementById('root')
);

if ((module as any).hot) {
    (module as any).hot.accept();
}
