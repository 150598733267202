import { useState } from 'react';

import Profile from './Profile';
import { Grid, Tabs, Tab, IconButton, Typography } from '@mui/material';
import Assessment from './Assessment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistoryBack } from '../../../hooks/useHistoryBack';

enum TabValue {
    PROFILE = 'profile',
    ASSESSMENT = 'assessment',
}

function ProfileOverview() {
    const historyBack = useHistoryBack('/candidate/search');

    const [tabValue, setTabValue] = useState(TabValue.PROFILE);

    const handleChangeTab = (_: React.SyntheticEvent, newValue: TabValue) => {
        setTabValue(newValue);
    };
    return (
        <>
            <Grid padding={2} sx={{ position: 'relative' }}>
                <IconButton
                    sx={{ position: 'absolute', top: 27, left: 16 }}
                    color="primary"
                    aria-label="back"
                    onClick={() => {
                        historyBack();
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Grid ml={3} mr={3} mb={3}>
                    <Tabs value={tabValue} onChange={handleChangeTab} centered>
                        <Tab
                            label={
                                <Typography variant="h5">
                                    <b>Profile</b>
                                </Typography>
                            }
                            value={TabValue.PROFILE}
                        />
                        <Tab
                            label={
                                <Typography variant="h5">
                                    <b>Assessment</b>
                                </Typography>
                            }
                            value={TabValue.ASSESSMENT}
                        />
                    </Tabs>
                </Grid>
                {tabValue === TabValue.PROFILE && <Profile />}
                {tabValue === TabValue.ASSESSMENT && <Assessment />}
            </Grid>
        </>
    );
}

export default ProfileOverview;
